import { Controller } from "@hotwired/stimulus";
import Cookies from "universal-cookie";
import { GRID_BREAKPOINTS } from "../entrypoints/application";

// Connects to data-controller="user_settings"
export default class UserSettings extends Controller {
  static targets = [
    "fontDropdown",
    "submitPassword",
    "currentPassword",
    "newPassword",
    "confirmationPassword",
    "loginUsername",
    "loginPassword",
    "loginButton",
  ];

  declare readonly fontDropdownTarget: HTMLSelectElement;
  declare readonly submitPasswordTarget: HTMLButtonElement;
  declare readonly currentPasswordTarget: HTMLInputElement;
  declare readonly newPasswordTarget: HTMLInputElement;
  declare readonly confirmationPasswordTarget: HTMLInputElement;
  declare readonly loginUsernameTarget: HTMLInputElement;
  declare readonly loginPasswordTarget: HTMLInputElement;
  declare readonly loginButtonTarget: HTMLButtonElement;

  private declare cookies: Cookies;
  private declare availableFonts: Array<string>;
  private declare shown: boolean;
  private declare cookieName: string;

  initialize() {
    this.cookies = new Cookies(null, { path: "/" });
    this.availableFonts = JSON.parse(this.data.get("availableFonts")!);
    this.shown = false;
    this.cookieName = "reading-font";

    // Cookie default
    if (this.cookies.get(this.cookieName) == null) {
      this.cookies.set(
        this.cookieName,
        this.availableFonts[0],
        {
          sameSite: true,
        },
      );
    }
  }

  connect() {
    const selectedFont = this.cookies.get(this.cookieName);

    this.fontDropdownTarget.value = selectedFont;
    this.setCssFont(selectedFont);

    // Hook the settings button outside of this controller
    this.hook();
  }

  selectFont(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedFont = target.value;
    if (!this.availableFonts.includes(selectedFont)) return;

    this.cookies.set(this.cookieName, selectedFont);
    this.setCssFont(selectedFont);
  }

  hook() {
    const button = document.getElementById("toggle-settings");
    const settingsContainer = document.querySelectorAll(".settings-container");
    if (button == null || settingsContainer.length == 0) return;

    const startIcon = button.querySelector("#s-icon")!;
    const endIcon = button.querySelector("#e-icon")!;
    const mobileStartIcon = button.querySelector("#ms-icon")!;
    const mobileEndIcon = button.querySelector("#me-icon")!;

    button.addEventListener("click", () => {
      this.shown = !this.shown;

      if (this.shown) {
        // First make it visible but still collapsed
        settingsContainer.forEach(c => c.classList.remove("d-none"));

        if (screen.width >= GRID_BREAKPOINTS.lg) {
          button.classList.add("opened");

          setTimeout(() => {
            settingsContainer.forEach(c => c.classList.remove("collapsed"));
          }, 10);
        }

        this.updateElementClasses(startIcon, { remove: ["fa-chevrons-left", "fa-chevron-left"] });
        this.updateElementClasses(endIcon, { add: ["fa-chevrons-right"] });

        this.updateElementClasses(
          [mobileStartIcon, mobileEndIcon],
          { add: ["fa-chevrons-up"], remove: ["fa-chevron-down"] },
        );
      } else {
        if (screen.width >= GRID_BREAKPOINTS.lg) {
          button.classList.remove("opened");

          // First collapse it
          settingsContainer.forEach(c => c.classList.add("collapsed"));

          // Then hide it after animation completes
          setTimeout(() => {
            settingsContainer.forEach(c => c.classList.add("d-none"));
          }, 300);
        } else {
          settingsContainer.forEach(c => c.classList.add("d-none"));
        }

        this.updateElementClasses(endIcon, { remove: ["fa-chevrons-right", "fa-chevron-right"] });
        this.updateElementClasses(startIcon, { add: ["fa-chevrons-left"] });

        this.updateElementClasses(
          [mobileStartIcon, mobileEndIcon],
          { add: ["fa-chevron-down"], remove: ["fa-chevrons-up"] },
        );
      }
    });

    button.addEventListener("mouseenter", () => {
      if (this.shown) {
        this.updateElementClasses(
          endIcon, {
            add: ["fa-chevrons-right"],
            remove: ["fa-chevron-right", "fa-chevrons-left", "fa-chevron-left"],
          },
        );
      } else {
        this.updateElementClasses(
          startIcon, {
            add: ["fa-chevrons-left"],
            remove: ["fa-chevron-left"],
          },
        );
      }
    });

    button.addEventListener("mouseleave", () => {
      if (this.shown) {
        this.updateElementClasses(
          endIcon, {
            add: ["fa-chevron-right"],
            remove: ["fa-chevrons-right"],
          },
        );
      } else {
        this.updateElementClasses(
          startIcon, {
            add: ["fa-chevron-left"],
            remove: ["fa-chevrons-left"],
          },
        );
      }
    });
  }

  validatePasswords() {
    const currentValid = this.currentPasswordTarget.value.length > 0;
    const newValid = this.newPasswordTarget.value.length > 0;
    const confirmationValid = this.confirmationPasswordTarget.value.length > 0;

    this.submitPasswordTarget.disabled
      = !currentValid || !newValid || !confirmationValid;
  }

  toggleLoginWindow() {
    const loginContainer = document.getElementById("login-container");
    if (loginContainer == null) return;

    const shown = !loginContainer.classList.contains("d-none");
    if (shown) {
      loginContainer.classList.add("d-none");
    } else {
      loginContainer.classList.remove("d-none");
    }
  }

  enableLoginButton() {
    const usernameValid = this.loginUsernameTarget.value.length > 0;
    const passwordValid = this.loginPasswordTarget.value.length > 0;

    this.loginButtonTarget.disabled = !usernameValid || !passwordValid;
  }

  private setCssFont(font: string) {
    document.documentElement.style.setProperty("--reading-font", font);
  }

  private updateElementClasses(
    elements: Element | Element[],
    { add = [], remove = [] }: { add?: string[]; remove?: string[] },
  ) {
    elements = Array.isArray(elements) ? elements : [elements];

    for (const element of elements) {
      element.classList.remove(...remove);
      element.classList.add(...add);
    }
  }
}
