import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cover-letter"
export default class extends Controller {
  static targets = [
    "container",
    "template",
    "companyNameInput",
    "companyNameOutput",
    "paragraphInput",
    "paragraphsOutput",
  ];

  static values = {
    paragraphs: { type: Array, default: [] },
  };

  paragraphsValueChanged() {
    this.paragraphsOutputTarget.value = JSON.stringify(this.paragraphsValue);
    this.renderParagraphs();
  }

  updateCompanyName() {
    this.companyNameOutputTarget.innerHTML = this.companyNameInputTarget.value;
  }

  insertParagraph() {
    let text = this.paragraphInputTarget.value;
    if (!text.trim()) return;

    this.paragraphsValue = [...this.paragraphsValue, text];
    this.paragraphInputTarget.value = "";
  }

  deleteParagraph(event) {
    const index = this.paragraphIndex(event);
    this.paragraphsValue = this.paragraphsValue.filter((_, i) => i !== index);
  }

  editParagraph(event) {
    const index = this.paragraphIndex(event);
    const text = this.paragraphsValue[index];

    const modalElement = document.getElementById("paragraph-modal");
    modalElement.dataset.editIndex = index;

    const bodyElement = document.getElementById("edit_text");
    bodyElement.value = text;

    bootstrap.Modal.getOrCreateInstance(modalElement).show();
  }

  updateParagraph() {
    const modalElement = document.getElementById("paragraph-modal");
    const textArea = document.getElementById("edit_text");

    const index = parseInt(modalElement.dataset.editIndex);
    const newText = textArea.value;

    const updatedParagraphs = [...this.paragraphsValue];
    updatedParagraphs[index] = newText;

    this.paragraphsValue = updatedParagraphs;

    bootstrap.Modal.getOrCreateInstance(modalElement).hide();
  }

  renderParagraphs() {
    this.containerTarget.innerHTML = "";

    this.paragraphsValue.forEach((text, index) => {
      const content = this.templateTarget.content.cloneNode(true);
      const paragraph = content.getElementById("inner");

      const rowElement = content.querySelector(".paragraph");
      rowElement.dataset.index = index;

      paragraph.innerHTML = text.replaceAll("\n", "<br>");

      this.containerTarget.appendChild(content);
    });
  }

  paragraphIndex(event) {
    const row = event.currentTarget.closest(".paragraph");
    return parseInt(row.dataset.index);
  }
}
